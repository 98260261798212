<template>
  <div id="app">
    
    <my-header v-if="!isPhone"></my-header>
    <router-view/>
    <wechat-nav></wechat-nav>
    <pay-modal></pay-modal>
  </div>
</template>

<script>
import MyHeader from '@C/MyHeader'
import MobileHeader from '@C/MobileHeader'
import {browserRedirect} from '@U/tool'
import {operationLog} from "@API/app"
import {apkChannel} from '@U/versionConfig'
import wechatNav from '@C/wechatNav'
import payModal from '@C/payModal'
export default {
    components: {
        MyHeader,
        MobileHeader,
        wechatNav,
        payModal
    },
    data() {
      return {
        isPhone: false
      }
    },
    mounted() {
        if(this.$route.query.apkChannel || this.$route.query.channel) {
            localStorage.setItem("apkChannel", this.$route.query.apkChannel || this.$route.query.channel)
        }
        else {
            localStorage.setItem("apkChannel", apkChannel)
        }
        this.isPhone = browserRedirect()
        operationLog({
            btnId: "pc-start up",
            type: '0'
        }).then(res => {
            
        });
    },
    computed: {
    },
    watch: {
      
    }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
