<template>
    <div class="mobile-header" >
       <!-- 标题 -->
        <div class="title-container row" id="mobile-header">
            <img @click="showLeftPopup"  class="img-tab" src="@A/images/mobile/mobile_tab.png" alt="">
            <div class="center row-center">
                <img  class="img-title" @click="toHome" src="@A/images/mobile/mobile_title.png" alt="">
            </div>
        </div>

         <!-- 左侧弹框 -->
        <van-popup  position="left" :style="{ width: '50%',height:'100%' }"
         v-model="showLeftPop" closeable  close-icon-position="top-left">
            <div class="column-start">
                <div @click="toHome" class="find-photo nr">首页</div>
                <div @click="findPhoto" class="find-photo mt20 nr">找回照片</div>
                <div @click="appDownload" class="app-download nr" v-if="false">APP下载</div>
            </div>
        </van-popup>    
    </div>

</template>
<script>
import {apkChannel} from "@U/versionConfig"
export default {
    data() {
        return {
           showLeftPop: false,
        }
    },
    mounted() {
        localStorage.setItem("bd_vid", this.$route.query.bd_vid)
        this.bd_vid = this.$route.query.bd_vid || localStorage.getItem("bd_vid");
    },
    methods: {
       // 弹出左侧弹框
        showLeftPopup(){
            this.showLeftPop = true;
        },
         // 找到图片
        findPhoto(){
            this.showLeftPop = false; 
            if(this.$route.name == 'find') {
                this.$emit("refresh")
                return;
            }
            this.$router.push({name: 'find', query: {
                channel: localStorage.getItem("apkChannel") || apkChannel,
                apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                bd_vid: this.bd_vid 
            }})
        },
        toHome() {
            this.showLeftPop = false;
            if(this.$route.name == 'index') {
                // this.$router.go(0);
                this.$emit("refresh")
                return;
            }
            this.$router.push({
                name: "index",
                query: {
                    channel: localStorage.getItem("apkChannel") || apkChannel,
                    apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                    bd_vid: this.bd_vid 
                }
            })   
        },
        // APP下载
        appDownload(){
            var u = navigator.userAgent;

            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isiOS) {
                // 这是IOS系统
                // window.open("https://itunes.apple.com/cn/app/id1590298047")
            }
            else
            {
                // 这是Android系统
                window.open("https://sm-upload.oss-cn-guangzhou.aliyuncs.com/upload/apk/identification_photo/com.soouya.identificaitonphoto_Yingyongbao.apk")
            }
        } 
    }
}
</script>

<style lang="scss" scoped>
.mobile-header {
    // 标题
    .title-container{
        background-color: white;
        width: 100%;
        height: 102px;
        position: fixed;
        top: 0;
        z-index: 100;
        border-bottom: 1px solid #E5E5E5;
        .img-tab{
            width: 28px;
            height: 24px;
            position: absolute;
            margin-left: 32px;
        }
        .center{
            width: 100%;
            .img-title{
            width: 247px;
            height: 46px;
            }
        }
        
    }

    .find-photo{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 40px;
        text-align: left;
        margin-left: 64px;
        &:first-of-type {
            margin-top: 100px;
        }
    }
    .app-download{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 40px;
        margin-top: 64px;
        text-align: left;
        margin-left: 64px;
              
    }
}
</style>