<template>
<div>
    <mobile-header></mobile-header>
    <div class="pay-result-container" v-show="findStatus == 0">
        <div class="column-center content">
            <img class="pay-status-icon" src="@A/images/mobile/paySuccess.png" alt="">
            <div class="pay-tips xxl">
                已成功支付，即将自动下载
            </div>
            <div class="download-container">
                <div class="download-btn row-center xs" @click="downloadPicture">下载图片</div>
                <div class="download-tips xs">如浏览器未自动下载照片，请点击以上按钮</div>
            </div>
        </div>
    </div>    
    <div class="picture-container" :style="{'background-color': btnShow ? 'white' : '#eff3f4'}" v-show="findStatus == 1" >
            <div class="main">
                <div class="tips" v-show="!btnShow">{{btnText}}</div>
                <img class="exported-image" :src="base64DPI300" alt="">
            </div>
            <div class="control row-center">
                <van-button class="long-btn row-center br60" v-show="btnShow">{{btnText}}</van-button>
                <img class="hidden-image" @click="clickDownload" :style="{'heihgt': phoneHeight + 'px'}"  :src="base64DPI300" alt="exported-image">
            </div>
        </div>
</div>
</template>

<script>
import "lib-flexible/flexible";
import {findPicture, operationLog, searchOrder} from '@API/app';
import {changeDpiDataUrl} from "changedpi"
import MobileHeader from '@C/MobileHeader'
import {mapGetters} from 'vuex'
import md5 from '@U/md5'
import axios from 'axios'
import JSMD5 from 'js-md5'
export default {
    data() {
        return {
            orderId: '',
            headerHeight: 0,
            resultPicture: "",
            findStatus: 0,
            errorText: '',
            spec_width: 0,
            spec_height: 0,
            color: "",
            title: "",
            dpi: 300,
            base64DPI300: "",
            phoneHeight: 667,
            platform: '',
            btnText: "长按图片保存证件照",
            btnShow: false
        }
    },
    components: {
        MobileHeader
    },
    mounted() {
        let u = navigator.userAgent;
        this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        let ua = navigator.userAgent.toLowerCase();
        // 删除制作好的图片 清除状态
        localStorage.setItem('imageShown', '');
        localStorage.setItem('colorSelect', '');
        localStorage.setItem('pictureBackground', '');
        localStorage.setItem('isBeauty', '');
        localStorage.setItem('selectedList', '');
        localStorage.setItem('selectedSizeX', '');
        localStorage.setItem('selectedSizeY', '');
        localStorage.removeItem('imageShown');
        localStorage.removeItem('colorSelect');
        localStorage.removeItem("pictureBackground");
        localStorage.removeItem("isBeauty");
        localStorage.removeItem('selectedList');
        localStorage.removeItem('selectedSizeX');
        localStorage.removeItem('selectedSizeY');
        // this.isDownLoad = false;
        if(ua.indexOf('baidu') != -1) {
            this.platform = 'baidu'
            if(this.isiOS) {
                this.btnText = "点击保存证件照"
                this.btnShow = true
            }
        }
        this.orderId = this.$route.query.orderId || this.$route.query.out_trade_no || localStorage.getItem('orderId');
        setTimeout(() => {
            localStorage.setItem("showPayModal", 0);
            this.$store.dispatch("hidePayModal");
        }, 1000)
        this.phoneHeight = window.innerHeight;
        this.$searchOrder();
    },
    methods: {
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        $searchOrder() {
            searchOrder({id: this.orderId}).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        console.log("result search!!", res.obj.extendJson)
                        this.findStatus = 1;
                        this.resultPicture = res.obj.uidFolder;
                        let orderInfo = res.obj && JSON.parse(res.obj.extendJson);
                        this.pxSizeX = orderInfo.pxSizeX;
                        this.pxSizeY = orderInfo.pxSizeY;
                        this.color = orderInfo.bgcolor;
                        this.title = orderInfo.goodsName;
                        this.dpi = orderInfo.dpi;
                        this.downloadPicture()
                    }
                    else {
                        this.findStatus = 0;
                    }
                }
                else {
                    this.findStatus = 0;
                }
            })
        },
        downloadPicture() {
            let that = this
            let picture_link = document.createElement('a');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.setAttribute("id", "result-picture")
            canvas.width = this.pxSizeX;
            canvas.height = this.pxSizeY;
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.resultPicture;
            image.width = this.pxSizeX;
            image.height = this.pxSizeY;
            ctx.fillStyle = this.color;
            ctx.fillRect(0, 0, this.pxSizeX, this.pxSizeY)
            image.onload = function() {
                // document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, that.dpi)
                that.base64DPI300 = dataURL300DPI;
                let resultBlob = that.dataURItoBlob(dataURL300DPI);
                that.findStatus = 1;
                if(that.platform == 'baidu') {
                    if(that.isiOS) {
                        that.uploadFile(new File([resultBlob], new Date().getTime() + '.jpg'))
                    }
                }
                that.uploadResult(new File([resultBlob], new Date().getTime() + '.jpg'))
                // picture_link.href = dataURL300DPI;
                // picture_link.target = "_blank";
                // picture_link.download = that.title + ".jpg";
                // picture_link.click()
            }
        },
        uploadFile(file) {
            let params = new FormData();
            params.append("file", file);
            let picture_link = document.createElement('a');
            let time = new Date().getTime()
            params.append("_time", time);
            params.append("_sign", JSMD5(`lx0e@_model=pc&_time=${time}n0*32`));
            return new Promise((resolve) => {
                axios
                    .post("/v1/uploadFile?_model=pc", params, {
                        headers: { 
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        if (data.success == 1) {
                            this.resultPicture = data.obj;
                            if(this.isiOS) {
                                location.href = data.obj;
                            }
                            else {

                            }
                        } else {
                            this.$toast("上传失败，请重新上传");
                            // this.showUploadPicture = false;
                            // this.fileList = [];
                        }
                    }).catch(err => {
                        this.$toast("上传出错")
                        // this.showUploadPicture = false;
                        // this.fileList = [];
                    });
            });
        },
        /* base64转Blob对象 */
        dataURItoBlob(data) {
            let byteString;
            if(data.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(data.split(',')[1])
            }else {
                byteString = unescape(data.split(',')[1])
            }
            let mimeString = data
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];
            let ia = new Uint8Array(byteString.length)
            for( let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], {type: mimeString})
        },
        clickDownload() {
            if(this.resultPicture == '') {
                return
            }
            if( this.platform == 'baidu') {
                if(this.isiOS) {
                    location.href = this.resultPicture;
                }
                else {
                    // let picture_link = document.createElement('a');
                    // picture_link.href = this.resultPicture;
                    // picture_link.target = "_blank";
                    // picture_link.download = this.title + ".jpg";
                    // picture_link.click();
                }
            }
        },
        uploadResult(file) {
            let params = new FormData();
            params.append("file", file);
            params.append('orderId', this.orderId)
            let time = new Date().getTime();
            params.append("_time", time);
            params.append("_sign", JSMD5(`lx0e@_time=${time}&orderId=${this.orderId}n0*32`));
            let picture_link = document.createElement('a');
            return new Promise((resolve) => {
                axios
                    .post("/v1/uploadOrderPic", params, {
                        headers: { 
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        if (data.success == 1) {
                            // this.resultPicture = data.obj;
                            if(this.isiOS) {
                                // location.href = data.obj;
                            }
                            else {

                            }
                        } else {
                        }
                    }).catch(err => {
                        
                    });
            });
        }
    },
    watch: {
        vuxIsShowPay(newVal, oldVal) {
            if(newVal == true) {
                this.$store.dispatch("hidePayModal")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .pay-result-container {
        background-color: white;
        height: 100vh;
        .content {
            padding-top: 240px;
            .pay-status-icon {
                width: 178px;
                height: 178px;
            }
            .pay-tips {
                font-weight: 500;
                font-family: PingFangSC-Medium, PingFang SC;
                color: #111111;
                margin-top: 32px;
            }
            .download-container {
                width: 100%;
                .download-btn {
                    margin: 88px 135px 0;
                    height: 60px;
                    font-weight: 500;
                    color: $--color-primary;
                    border: 1px solid $--color-primary;
                    border-radius: 4px;
                }
                .download-tips {
                    margin-top: 24px;
                    color: #808080;
                    line-height: 33px;
                }
            }
        }
    }
    .picture-container {
            height: calc(100vh - 101px);
            min-height: calc(100vh - 101px);
            background-color: #eff3f4;
            margin-top: 100px;
            .main {
                height: calc(100% - 201px);
                background-color: #eff3f4;
                padding: 54px 32px;
                box-sizing: border-box;
                .exported-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-style: none;
                }
            }
            .control {
                // background-color: white;
                padding: 48px;
                .long-btn {
                    border-radius: 120px;
                    height: 100px;
                    width: 100%;
                    color: white;
                    background-color: $--color-primary;
                    // border: none;
                }
                .hidden-image {
                    position: absolute;
                    height: 100vh;
                    width: 100vw;
                    object-fit: cover;
                    opacity: 0;
                    left: 0;
                    top: 0;
                }
            }
        }
</style>