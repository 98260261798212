import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import Router from '@/router'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        showPayModal: false,
        routes: [],
        activeRoute: ""
    },
    mutations: {
        setPayModal(state, status) {
            console.log("vuexShowPayModal ==> ", status)
            state.showPayModal = status;
        },
        setRoutes(state, routes) {
            state.routes = routes;
        },
        setActiveRoute(state, routes) {
            state.activeRoute = routes
        }
    },
    actions: {
        showPayModal({commit}) {
            console.log("current name ==>  ", Router.history.current.name)
            // if(Router.history.current.name == 'result') {
            //     commit('setPayModal', false)
            //     return;
            // }
            // else {
            commit("setPayModal", true)
            // }
        },
        hidePayModal({commit}) {
            commit("setPayModal", false)
        }
    },
    modules: {
    },
    getters
})
