import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Storage from 'vue-ls';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.scss'
import './permission'
// import 'lib-flexible/flexible'
import {Switch, Popup, Picker, Uploader, 
  Button, Image as VanImage, Loading, Icon 
} from "vant"
import 'vant/lib/index.css';
import VConsole from 'vconsole';
// if (location.hostname.indexOf('test') !== -1 || location.hostname == 'localhost' || location.hostname == '192.168.1.71') {
//   var vConsole = new VConsole();
// }
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Switch).use(Uploader).use(Picker).use(Popup).use(Button).use(VanImage).use(Loading).use(Icon)
let options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}
Vue.use(Storage, options);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
