<template>
    <div class="wechat-nav-container">
        <transition name="fade">
            <div class="wechat-nav" v-show="showWechatNav" @touchmove.prevent="" @click.stop="">
                <img  src="@A/images/mobile/wechat.png" alt="">
            </div>
        </transition>
    </div>
</template>

<script>
import {isWechat} from '@U/tool'
import {browserRedirect} from "@U/tool"
export default {
    data() {
        return {
            showWechatNav: false
        }
    },
    mounted() {
        if(browserRedirect()) {
            // 如果是手机版打开，要判断是否为微信
            this.showWechatNav = isWechat()
        }
    },
}
</script>

<style lang="scss" scoped>
.wechat-nav-container {
    position: relative;
    z-index: 200;
    .wechat-nav {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        z-index: 200;
        img {
            width: 90%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0
    }
}
</style>