export const ACCESS_TOKEN = 'Access-Token'
// 请求域名 格式： https://您的域名
let isHttps = (window.location.origin.indexOf('https:') > -1)
let proc
if(isHttps) {
  proc = 'https://'
} else {
  proc = 'http://'
}

let baseUrl 
let nenv = process.env.NODE_ENV
if(nenv == 'production') {
  if(window.location.host.indexOf('test') == -1) {
    console.log('生产环境')
    // baseUrl = proc + 'www.duoduozhishu.cn:18036/v1/'
    baseUrl = proc + 'api.cameraMagic.shunmakeji.com/v1/'
  } else {
    console.log('测试环境')
    // baseUrl = proc + 'test.duoduozhishu.cn:18036/v1/'
    baseUrl = proc + 'testmy.shunmakeji.com/v1/'
  }
// } else if(nenv == 'test') {
} else { //dev
  console.log('开发环境环境')
  baseUrl = '/v1/' //用上代理
}

export const HTTP_URL = baseUrl