<template>
    <div @touchmove.prevent class="pay-modal-container" v-show="vuxIsShowPay">
        <div class="pay-modal-box">
            <div class="header-title row-center">
                <div class="xxl bold">提示</div>
                <van-icon class="close-icon" name="cross" @click="closeBox" />
            </div>
            <div class="tips-info xl bold">
                {{tipTitle}}
            </div>
            <div class="btn-container mt20 column">
                <button class="pay-complete-btn row-center white br60" v-show="isPay" @click="onPayFn">已完成支付</button>
                <button class="mt20 muted br60" :class="[isPay ? 'text-btn' : 'pay-complete-btn']" v-show="isResetPay" @click="resetPay">{{buttonTitle}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {apkChannel, apkVersionName, phoneModel, apkVersionCode, apkId, phoneOsVersion, phoneOs} from '@U/versionConfig'
import {searchOrder, pay} from '@API/app'
export default {
    props: {

    },
    data() {
        return {
            tipTitle: "是否完成支付？", // '是否完成支付？',支付完成后，跳去支付成功页
            buttonTitle: "继续支付", // '已完成支付'  马上支付
            isPay: true,
            isResetPay: true,
            orderId: '',
            payWay: 4,
        }
    },
    mounted() {
        localStorage.setItem("bd_vid", this.$route.query.bd_vid)
        this.bd_vid = this.$route.query.bd_vid || localStorage.getItem("bd_vid");
        if(localStorage.getItem("phoneId")) {
            this.phoneId = localStorage.getItem("phoneId");
        }
        if(localStorage.getItem("payWay")) {
            this.payWay = localStorage.getItem("payWay") || 4
        }
        if(this.$route.name == 'result') {
            this.$store.dispatch("hidePayModal");
            // 把弹窗状态关闭
            localStorage.setItem("showPayModal", 0);
        }
        // ## 这儿的orderId和findPhoto的orderId不一样
        this.orderId = localStorage.getItem("orderId");
    },
    destroyed() {
        this.tipTitle = "是否完成支付？";
        this.buttonTitle = "继续支付";
        this.isResetPay = true;
        this.isPay = true;
        if(this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        // 埋点
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        $searchOrder(id) {
            searchOrder({
                id,
            }).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        if(this.timer) {
                            clearInterval(this.timer)
                        }
                        this.paySuccess()
                    }
                    else {
                        // 没有支付完成
                        this.tipTitle = "订单未支付，请点击继续支付按钮重新支付。";
                        this.isResetPay = true;
                        this.isPay = false;
                        // end
                    }
                }
            })
        },
        paySuccess() {
            this.$toast("支付成功");
            // this.$store.dispatch("hidePayModal");
            // 把弹窗状态关闭
            // localStorage.setItem("showPayModal", 0);
            if(this.$route.name == "result") {
                this.$store.dispatch("hidePayModal");
                // 把弹窗状态关闭
                localStorage.setItem("showPayModal", 0);
            }
            console.log("paySucces !!!!")
            // 删除制作好的图片 清除状态
            localStorage.setItem('imageShown', '');
            localStorage.setItem('colorSelect', '');
            localStorage.setItem('pictureBackground', '');
            localStorage.setItem('isBeauty', '');
            localStorage.setItem('selectedList', '');
            localStorage.setItem('selectedSizeX', '');
            localStorage.setItem('selectedSizeY', '');
            localStorage.removeItem('imageShown');
            localStorage.removeItem('colorSelect');
            localStorage.removeItem("pictureBackground");
            localStorage.removeItem("isBeauty");
            localStorage.removeItem('selectedList');
            localStorage.removeItem('selectedSizeX');
            localStorage.removeItem('selectedSizeY');
            localStorage.removeItem("payOrder");
            // 清除orderId
            // localStorage.removeItem("orderId");
            // this.isDownLoad = false;
            this.$router.replace({
                name: 'result',
                query: {
                    orderId: this.orderId || localStorage.getItem("orderId"),
                    channel: localStorage.getItem("apkChannel") || apkChannel,
                    apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                    bd_vid: this.bd_vid
                }
            });
        },
        // 已完成支付按钮点击
        onPayFn() {
            this.$searchOrder(this.orderId || localStorage.getItem("orderId"))
        },
        // 重新发起支付
        resetPay() {
            let payObject = localStorage.getItem("payObject") && JSON.parse(localStorage.getItem("payObject"))
            payObject['id'] = this.orderId || localStorage.getItem("orderId");
            if(payObject.payWay == 4) {
                setTimeout(() => {
                    localStorage.setItem("showPayModal", 1)
                    this.$store.dispatch("showPayModal");
                }, 1000)
            }
            searchOrder({id: this.orderId || localStorage.getItem("orderId")}).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        // 若已经支付了
                        this.$toast("订单已支付");
                        this.paySuccess()
                    }
                    else {
                        pay(payObject).then(res => {
                            if(payObject.payWay == 4) {
                                
                                window.location.href = res.sign.html;
                            }
                            else if(payObject.payWay == 5) {
                                // 支付宝支付
                                let path = res.html;
                                localStorage.setItem("showPayModal", 0);
                                // window.open(path);
                                window.location.href = path;
                                // this.payQRCode = path;
                                // console.log("QRCode", this.payQRCode)
                            }
                            // this.timer = setInterval(() => {
                            //     this.$searchOrder(this.orderId || localStorage.getItem("orderId"))
                            // }, 1000)
                        })
                    }
                }
            })
        },
        closeBox() {
            this.initStatus();
            // 若用户支付成功就直接跳结果页
            searchOrder({id: this.orderId}).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        if(this.timer) {
                            clearInterval(this.timer)
                        }
                        // 已支付了
                        this.paySuccess()
                    }
                    else {
                        // 没有支付完成
                        this.$store.dispatch("hidePayModal");
                        // 把弹窗状态关闭
                        localStorage.setItem("showPayModal", 0);
                        if(this.timer) {
                            clearInterval(this.timer)
                        }
                        // end
                    }
                }
                else {
                    this.$store.dispatch("hidePayModal");
                    // 把弹窗状态关闭
                    localStorage.setItem("showPayModal", 0);
                    if(this.timer) {
                        clearInterval(this.timer)
                    }
                }
            })
        },
        initStatus() {
            this.tipTitle = "是否完成支付？";
            this.buttonTitle = "继续支付";
            this.isResetPay = true;
            this.isPay = true;
        }
    },
    watch: {
        vuxIsShowPay(newVal, oldVal) {
            this.orderId = localStorage.getItem("orderId");
            // if(this.$route.name == 'result' && newVal == true) {
            //     return;
            // }
        }
    },
    computed: {
        ...mapGetters(["vuxIsShowPay"])
    }
}
</script>

<style lang="scss" scoped>
    .pay-modal-container {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3000;
        .pay-modal-box {
            position: fixed;
            border-radius: 4px;
            width: 220px;
            height: 200px;
            max-width: 300px;
            max-height: 200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white;
            padding: 0 40px;
            .header-title {
                height: 40px;
                .close-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 10px;
                }
            }
            .tips-info {
                
            }
            .btn-container {
                .pay-complete-btn {
                    background-color: $--color-primary;
                    color: white;
                    height: 47px;
                }
                .text-btn {

                }
            }
        }
    }
</style>