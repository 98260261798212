export function browserRedirect() {
  var sUserAgent = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
      //跳转移动端页面 
      return true
  } else {
      //跳转pc端页面
      return false
  }
}

export function getUUid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
      i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  }

    // 获取手机型号版本
export function  getPhoneMaker() {
        let userAgent = navigator.userAgent;
        let phoneOsVersion = '';
        let phoneOs = 0;
        // 苹果手机
        if (userAgent.includes('iPhone')) {
          // 获取操作系统版本
          phoneOs = 1
          let m1 = userAgent.match(/iPhone OS .*?(?= )/)
          phoneOsVersion = m1[0].slice(10);
          localStorage.setItem('phoneModel', 'iPhone')
          localStorage.setItem('phoneMaker', 'iPhone OS')
        } else {
          phoneOs = 0
          let a1 = userAgent.match(/Android .*?(?= )/)
          if(a1 instanceof Array) {
            phoneOsVersion = a1[0].slice(8);
          }
          else {
            phoneOsVersion = "13_2_3"
          }
          var st = userAgent.split(";")[2];
          let phoneMaker = st.split("/")[0];
          phoneMaker = phoneMaker.replace("Build", "");
          phoneMaker = phoneMaker.replace("AppleWebKit", "")
          phoneMaker = phoneMaker.replace(/(^\s*)|(\s*$)/g, "");;
          localStorage.setItem('phoneModel', 'Android')
          localStorage.setItem('phoneMaker', phoneMaker)
    
        }
        localStorage.setItem('phoneOs', phoneOs)
        localStorage.setItem('phoneOsVersion', phoneOsVersion)
      }
    
      //获取URL参数(‘针对APP做的处理’)
export function getParam(paramName) {
    let paramValue = "", isFound = !1;
    if (location.search.indexOf("?") == 0 && location.search.indexOf("=") > 1) {
        let arrSource = unescape(location.search).substring(1, location.search.length).split("&"), i = 0;
        while (i < arrSource.length && !isFound) arrSource[i].indexOf("=") > 0 && arrSource[i].split("=")[0].toLowerCase() == paramName.toLowerCase() && (paramValue = arrSource[i].split("=")[1], isFound = !0), i++
    }
    if (paramName == 'phoneId') {
        // 接口请求时会再cookie 添加userUniqueId 做为用户唯一标识（H5端）
        paramValue = this.getCookie('userUniqueId')
    } else {
        localStorage.setItem(paramName, paramValue)
    }
    if (!paramValue) {
        paramValue = '';
    }
    return paramValue
}
    
      //获取Cookie参数(‘’)
export function getCookie(cookie_name) {
    var allcookies = document.cookie;
    var cookie_pos = allcookies.indexOf(cookie_name);   //索引的长度  
    // 如果找到了索引，就代表cookie存在，  
    // 反之，就说明不存在。  
    if (cookie_pos != -1) {
        // 把cookie_pos放在值的开始，只要给值加1即可。  
        cookie_pos += cookie_name.length + 1;      //这里我自己试过，容易出问题，所以请大家参考的时候自己好好研究一下。。。  
        var cookie_end = allcookies.indexOf(";", cookie_pos);
        if (cookie_end == -1) {
            cookie_end = allcookies.length;
        }
        var value = unescape(allcookies.substring(cookie_pos, cookie_end)); //这里就可以得到你想要的cookie的值了。。。  
    }
    if (cookie_name == 'userUniqueId' && cookie_name && value) {
        //(设置 phoneId （phoneId相当于userUniqueId）)
        localStorage.setItem('phoneId', value)
    }

    return value;
}

// 判断是否为微信
export function isWechat() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //document.write("是微信浏览器//");
      return true;
    }
    else {
      // document.write("不是微信浏览器//");
      return false;
    }
}

export function isMobile() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      return true;
    } else {
      return false;
    }
  }

  // 传入一个Date对象
export  function getDate(timestamp) {
  let now = timestamp;
  let y = now.getFullYear();
  let m = now.getMonth() + 1;
  let d = now.getDate();
  // "2021-12-11_12:00"
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 5);
}


// 函数节流
export const throttle = function(fn, delay){
  var timer;
  return function () {
      var _this = this;
      var args = arguments;
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(function () {
          fn.apply(_this, args);
      }, delay)
  }
}

// 函数防抖
export const shakeProof = (fn, time) => {
  var timer;
  return function(){
      var _this = this;
      var args = arguments;
      if(timer){
          clearTimeout(timer);
      }
      timer = setTimeout(function(){
          fn.apply(_this, args);// 用 apply 指向调用 shakeProof 方法的对象，相当于 _this.fn(args);
      }, time);
  }
}