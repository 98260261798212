import router from '@/router';
import store from './store'
router.beforeEach(async (to, from, next) => {
    /* 路由发生变化修改页面title */
    console.log(to, "from ==> ", from)
    if(to.name == 'result') {
        console.log('name ==> result')
        store.dispatch("hidePayModal");
        // 把弹窗状态关闭
        window.localStorage.setItem("showPayModal", 0);
    }
    console.log('### next ###')
    next()
})

router.beforeResolve((to, from, next) => {
    console.log('router resolveBefore')
	next()
})

router.afterEach((to, from) => {
	console.log('afterEach')
})