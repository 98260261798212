import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {browserRedirect} from "@U/tool"
Vue.use(VueRouter)
import payResult from '@V/payResult'
const routes = [
  {
    path: '/',
    name: 'index',
    component: browserRedirect() ? () => import("@V/mobile") : () => import("@V/index")
    // component: () => import("@V/index")
  },
  {
    path: "/find",
    name: "find",
    component:  browserRedirect() ? () => import("@V/findphoto") :  () => import('@V/findPicture')
    // component:   () => import('@V/findPicture')
  },
  {
    path: "/result",
    name: "result",
    component: payResult
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
