import request from "@U/request"
import {random_int_str} from '@U/utils'
import {apkChannel, apkVersionName, phoneModel, apkVersionCode, apkId, phoneOsVersion, phoneOs} from '@U/versionConfig'
// 获取配置
export function getSetup(params) {
    return request.get("setup/listConfig", {params})
}

// 获取价格列表
export function getPriceSetup(params) {
    return request.get("priceSetup", {params})
}

// 上传文件
export function upload2Image(data) {
    return request.post("uploadFile?_model=src", data, {
        headers: { 
            "Content-Type": "multipart/form-data",
        },
    });
}

// 抠图
export function handleImage(params) {
    return request.get("koutu", {params})
}

// 生成证件照（不换装接口）
export function createCrditUnChange(params) {
    return request.get("buhuanzhuang", {params})
}

// 下单
export function pay(data) {
    return request.post("order", data)
}

// 订单查询
export function searchOrder(params) {
    return request.get("omsOrder/getPicById", {params})
}

// 埋点
export function operationLog(data) {
    let phoneId = ""
    if(localStorage.getItem("phoneId")) {
            phoneId = localStorage.getItem("phoneId");
    }
    else {
        phoneId = 'PC_phoneID_' + random_int_str(6);
        localStorage.setItem("phoneId", phoneId)
    }
    return request.post("operationLog", {...data, apkChannel: localStorage.getItem("apkChannel") || apkChannel, apkVersionName, phoneModel, apkVersionCode, apkId, phoneOsVersion, phoneOs, phoneId})
}

// 找回照片
export function findPicture(params) {
    return request.get("omsOrder/findBackPic", {params})
}