import axios from 'axios'
import Vue from 'vue'
import { HTTP_URL } from '@/configs'
import  router  from '@/router'
import { Message } from 'element-ui';
import { Toast } from 'vant';
import {browserRedirect} from "@U/tool"
import store from "../store";
import md5 from '@U/md5'
import JSMD5 from 'js-md5'
// 创建axios实例
const service = axios.create({
    // baseURL: "/v1/", // api的base_url
    baseURL: HTTP_URL, // api的base_url
    timeout: 10000, // 请求超时时间
    headers: {
        'content-type': 'application/json'
    },
})

// loading计算方法
let loading = null
let timer = null
let needLoadingRequestCount = 0 //正在请求网络的接口个数，大于0 并且 500毫秒内接口响应未完成时显示loading

function showFullScreenLoading () {
    Toast.allowMultiple()
    clearTimeout(timer)
    timer = setTimeout(() => {
        if (!loading && needLoadingRequestCount > 0) {
            loading = Toast.loading({
                duration: 0,       
                forbidClick: true, 
                loadingType: 'spinner',
                message: '请稍候'
            });
        }
    }, 500);
    needLoadingRequestCount ++
}

function tryHideFullScreenLoading () {
    if (needLoadingRequestCount <= 0) return
        needLoadingRequestCount --
    if (needLoadingRequestCount === 0 && loading) {
        loading.clear()
        loading = null
    }
}
let index = 0


// request拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // console.log('xhrConfig:', config)
        // if(config.method == "post") {
        //     config.headers = {

        //     }
        // }
        // console.log(config.url != 'uploadFile')
        let _sign = ""
        let nowTime = new Date().getTime()
        let str = "_time=" + nowTime;
        let keyArray = []
        if(config.method.toLowerCase() == 'post') {
            for(let key in config.data) {
                keyArray.push(key) 
            }
            keyArray = keyArray.sort();
            keyArray.forEach(item => {
                str +=  "&" + item + '=' + config.data[item];
            })
            console.log('str ==> ' + str)
            _sign = JSMD5("lx0e@" + str + "n0*32");
            config.data['_sign'] = _sign;
            config.data['_time'] = nowTime
        }
        if(config.method.toLowerCase() == 'get') {
            for(let key in config.params) {
                keyArray.push(key) 
            }
            keyArray = keyArray.sort();
            keyArray.forEach(item => {
                str +=  "&" + item + '=' + config.params[item];
            })
            // console.log('str ==> ' + str)
            _sign = JSMD5("lx0e@" + str + "n0*32");
            config.params['_sign'] = _sign;
            config.params['_time'] = nowTime;
        }
        console.log(_sign)
        console.log(config)
        return config
    },
    error => {
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    async (response) => {
        // tryHideFullScreenLoading()
        let { success, msg } = response.data;
        if(success == "1") {
            return Promise.resolve(response.data)
        }
        else {
            if(browserRedirect()) {
                if(response.config.url == 'buhuanzhuang') {
                    console.log("reject buhuanzhuang")
                    return Promise.reject(response.data)
                }
                Toast(msg)
            }
            else {
                Message.error(msg)
            }
            console.log("reject", response)
            return Promise.reject(response.data)
        }
    },
    error => {
        // tryHideFullScreenLoading()
        console.log(error)
        console.log('err' + error) // for debug
        if(browserRedirect()) {
            Toast("错误：", error)
        }
        else {
            Message.error(error)
        }
        return Promise.reject(error)
    }
)

export default service
